import React from "react"
import theme from "theme"
import { Theme, Text, Link, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Головна | гольф-клуб Greenfield</title>
        <meta
          name={"description"}
          content={"Тестуйте гольф в найкращих випадках"}
        />
        <meta
          property={"og:title"}
          content={"Головна | гольф-клуб Greenfield"}
        />
        <meta
          property={"og:description"}
          content={"Тестуйте гольф в найкращих випадках"}
        />
        <meta
          property={"og:image"}
          content={"https://luxivance.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://luxivance.com/img/186176.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://luxivance.com/img/186176.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://luxivance.com/img/186176.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://luxivance.com/img/186176.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://luxivance.com/img/186176.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://luxivance.com/img/186176.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="140px 0 140px 0"
        background="linear-gradient(0deg,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0.5) 100%),#FFFFFF url(https://luxivance.com/img/1.jpg) 100% 100% /100% no-repeat scroll padding-box"
        min-height="100vh"
        lg-background="#fff"
        md-padding="96px 0 70px 0"
        sm-padding="72px 0 70px 0"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="60%"
          flex-direction="column"
          justify-content="center"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 60px 0px"
          sm-margin="0px 0px 40px 0px"
          sm-padding="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 24px 0px"
            color="--dark"
            font="--headline1"
            lg-text-align="center"
            md-font='normal 700 40px/1.2 "Source Sans Pro", sans-serif'
          >
            гольф-клуб Greenfield
          </Text>
          <Text
            margin="0px 0px 40px 0px"
            color="--darkL2"
            font="--lead"
            lg-text-align="center"
          >
            Ми раді вітати вас у гольф-клубі Greenfield, де пристрасть до гольфу
            поєднується з красою природи.Наш клуб прагне забезпечити
            неперевершений досвід у гольф -гравців для гравців будь -якого
            рівня.Розташований серед чудових пейзажів та ретельно доглянуті
            зелені поля, Greenfield Golf Club пропонує спокійну та складну
            атмосферу, яка надихне вас на пограти та радувати свої почуття.
          </Text>
          <Box display="flex" sm-flex-direction="column" sm-text-align="center">
            <Link
              href="/contacts"
              padding="12px 24px 12px 24px"
              color="--light"
              background="--color-primary"
              text-decoration-line="initial"
              font="--lead"
              border-radius="8px"
              margin="0px 16px 0px 0px"
              sm-margin="0px 0px 16px 0px"
              sm-text-align="center"
              hover-background="--color-orange"
              hover-transition="background-color 0.2s ease-in-out 0s"
              transition="background-color 0.2s ease-in-out 0s"
              flex-direction="row"
            >
              Зв'язатися з нами
            </Link>
          </Box>
        </Box>
        <Box
          display="flex"
          width="50%"
          justify-content="center"
          overflow-y="hidden"
          overflow-x="hidden"
          lg-width="100%"
        />
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-3"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          display="flex"
          width="65%"
          align-items="flex-start"
          sm-margin="0px 0px 30px 0px"
          justify-content="center"
          lg-width="100%"
        >
          <Box
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            width="100%"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
            height="auto"
            padding="0px 0px 70% 0px"
          >
            <Image
              bottom={0}
              min-height="100%"
              src="https://luxivance.com/img/4.jpg"
              object-fit="cover"
              left={0}
              top={0}
              right={0}
              position="absolute"
              display="block"
              width="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          lg-order="1"
          width="35%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
        >
          <Box
            transform="translateY(0px)"
            width="100%"
            overflow-y="hidden"
            position="relative"
            hover-transform="translateY(-10px)"
            padding="0px 0px 65% 0px"
            height="auto"
            overflow-x="hidden"
            transition="transform 0.2s ease-in-out 0s"
          >
            <Image
              bottom={0}
              src="https://luxivance.com/img/3.jpg"
              width="100%"
              left={0}
              right={0}
              min-height="100%"
              object-fit="cover"
              position="absolute"
              display="block"
              top={0}
            />
          </Box>
          <Text
            margin="10px 0px 15px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font='normal 700 32px/1.2 "Source Sans Pro", sans-serif'
          >
            Про Нас
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Ми пишаємось тим, що наше поле настільки ж гарне, як складне.Команда
            професіоналів ретельно піклується про наше поле, яке піклується про
            те, що кожен раунд гри такий же приємний, як і попередній.
            Приголомшливий характер у поєднанні з нашим сучасним обладнанням
            створює неперевершені умови для гри в гольф.
          </Text>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Description-9"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-margin="0px 0px 30px 0px"
          sm-padding="0px 0px 0px 0px"
          align-items="flex-start"
          lg-align-items="center"
          flex-direction="column"
          lg-width="100%"
          lg-margin="0px 0px 0px 0px"
          padding="24px 24px 24px 24px"
          justify-content="space-between"
          display="flex"
          width="50%"
        >
          <Box>
            <Text
              margin="0px 0px 36px 0px"
              color="--darkL2"
              font="--headline2"
              lg-text-align="center"
              sm-font='normal 700 32px/1.2 "Source Sans Pro", sans-serif'
            >
              Переваги Greenfield
            </Text>
          </Box>
          <Text
            margin="0px 0px 0px 0px"
            color="--grey"
            font="--lead"
            lg-text-align="center"
          >
            Винятковий дизайн поля: Наше поле розроблено таким чином, щоб
            забезпечити складний, але приємний досвід для гольфістів будь-якого
            рівня.Завдяки стратегічному макету, кожен отвір - це унікальний
            тест.
            <br />
            <br />
            Зручність світового класу: кожен аспект гольф-клубу Greenfield - від
            повністю обладнаного магазину для професіоналів до елегантного
            будинку клубу - був створений з урахуванням вашого комфорту та
            зручності.Насолоджуйтесь вишуканими стравами, розслабляючим
            відпочинком та роздягальнями першого класу.
            <br />
            <br />
            Професійний інструктаж: Наша команда інструкторів, сертифікована
            PGA, готова допомогти вам покращити гру. Незалежно від того, вам
            потрібні приватні уроки чи групові заняття, наші інструктори
            пропонують індивідуальне навчання з урахуванням ваших потреб.
          </Text>
        </Box>
        <Box
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="24px 24px 24px 24px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            transform="translateY(0px)"
            hover-transform="translateY(-10px)"
            width="100%"
            height="auto"
            overflow-y="hidden"
            padding="0px 0px 100% 0px"
            overflow-x="hidden"
            position="relative"
            transition="transform 0.2s ease-in-out 0s"
          >
            <Image
              object-fit="cover"
              display="block"
              width="100%"
              right={0}
              src="https://luxivance.com/img/5.jpg"
              position="absolute"
              top="auto"
              left={0}
              bottom="0px"
              min-height="100%"
            />
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Description-9"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="24px 24px 24px 24px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            transform="translateY(0px)"
            hover-transform="translateY(-10px)"
            width="100%"
            height="auto"
            overflow-y="hidden"
            padding="0px 0px 100% 0px"
            overflow-x="hidden"
            position="relative"
            transition="transform 0.2s ease-in-out 0s"
          >
            <Image
              object-fit="cover"
              display="block"
              width="100%"
              right={0}
              src="https://luxivance.com/img/6.jpg"
              position="absolute"
              top="auto"
              left={0}
              bottom="0px"
              min-height="100%"
            />
          </Box>
        </Box>
        <Box
          sm-margin="0px 0px 30px 0px"
          sm-padding="0px 0px 0px 0px"
          align-items="flex-start"
          lg-align-items="center"
          flex-direction="column"
          lg-width="100%"
          lg-margin="0px 0px 0px 0px"
          padding="24px 24px 24px 24px"
          justify-content="space-between"
          display="flex"
          width="50%"
        >
          <Box>
            <Text
              margin="0px 0px 36px 0px"
              color="--darkL2"
              font="--headline2"
              lg-text-align="center"
              sm-font='normal 700 32px/1.2 "Source Sans Pro", sans-serif'
            >
              Приєднуйтесь до нас сьогодні
            </Text>
          </Box>
          <Text
            margin="0px 0px 0px 0px"
            color="--grey"
            font="--lead"
            lg-text-align="center"
          >
            Не пропустіть можливість познайомитися з гольф-клубом
            Greenfield.Якщо ви хочете стати членом клубу або просто провести
            день на полі, ми запрошуємо вас дізнатися, що наш клуб робить це
            особливим.Завітайте до нас і дізнайтеся, чому Greenfield Golf Club -
            ідеальне місце для вашої наступної пригоди для гольфу.
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
